import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // Recharts import
import { Box, ButtonGroup, Button, Typography } from '@mui/material'; // MUI import

// Custom color palette
const barColors = ['#82ca9d', '#8884d8', '#ff7300', '#ffbb28', '#d0ed57', '#8dd1e1', '#a4de6c'];

const HarvestsChart = ({ data, harvestView, setHarvestView }) => {
    // Get all keys except "monthYear"
    const barKeys = Object.keys(data[0] || {}).filter(key => key !== "monthYear");

    return (
        <Box sx={{ backgroundColor: "#1e1e1e", padding: 2 }}>
            <Typography variant="h6" color="white">Harvests by Month</Typography>
            <ButtonGroup variant="contained" size="small" sx={{ marginBottom: 2 }}>
                <Button onClick={() => setHarvestView('all')} variant={harvestView === 'all' ? 'contained' : 'outlined'}>All</Button>
                <Button onClick={() => setHarvestView('zone_id')} variant={harvestView === 'zone_id' ? 'contained' : 'outlined'}>Zone ID</Button>
                <Button onClick={() => setHarvestView('plant_type')} variant={harvestView === 'plant_type' ? 'contained' : 'outlined'}>Plant Type</Button>
            </ButtonGroup>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                    <XAxis dataKey="monthYear" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip />
                    <Legend />
                    {/* Cycle through bar keys and apply a color from the palette */}
                    {barKeys.map((key, index) => (
                        <Bar key={key} dataKey={key} fill={barColors[index % barColors.length]} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default HarvestsChart;
