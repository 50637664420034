import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button, ButtonGroup, Box, Typography, List, ListItem, Card, Select, MenuItem, Badge} from '@mui/material';

// Custom color palette
const colors = ['#82ca9d', '#8884d8', '#ff7300', '#ffbb28', '#d0ed57', '#8dd1e1', '#a4de6c'];
const currencyFormatter = (number) => {
    return 'NGN' + Intl.NumberFormat('us').format(number).toString();
};

// Function to extract unique years from the sales data
const extractAvailableYears = (data) => {
    const years = new Set();
    data.forEach(entry => {
        const year = entry.year;
        years.add(year);
    });
    return Array.from(years).sort(); // Return sorted array of unique years
};

const SalesChart = ({ data, salesView, setSalesView }) => {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // State to manage selected year and available years
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [availableYears, setAvailableYears] = useState([]);

    // Extract available years when the component mounts or data changes
    useEffect(() => {
        const years = extractAvailableYears(data);

        setAvailableYears(years);
        // If the current year isn't in the data, set the selected year to the first available year
        if (!years.includes(currentYear)) {
            setSelectedYear(years[0]);
        }
    }, [data]);

    // Calculate the total value for the selected year
    const filteredData = data.filter(entry => entry.year === selectedYear);

    const totalValue = filteredData.reduce((acc, entry) => acc + entry.value, 0);

    // Filter and format data based on the selected year
    const formattedData = filteredData
        .map((entry, index) => ({
            name: entry.name,
            value: entry.value,
            percentage: ((entry.value / totalValue) * 100).toFixed(1),
            color: colors[index % colors.length],
        })).sort((a, b) => b.percentage - a.percentage);;

    return (
        <Card sx={{ backgroundColor: '#1e1e1e', padding: 2, borderRadius: 4, boxShadow: 4 }}>
            {/* Header with year selection */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="white">
                    Sales by {salesView === 'zone_id' ? 'Zone ID' : 'Plant Type'}
                </Typography>
                {/* ButtonGroup to toggle between views */}
                <ButtonGroup variant="contained" size="small">
                    <Button onClick={() => setSalesView('plant_type')} variant={salesView === 'plant_type' ? 'contained' : 'outlined'}>
                        Plant Type
                    </Button>
                    <Button onClick={() => setSalesView('zone_id')} variant={salesView === 'zone_id' ? 'contained' : 'outlined'}>
                        Zone ID
                    </Button>
                </ButtonGroup>
                {/* Year Selector */}
                <Select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    sx={{ color: 'white', backgroundColor: '#333', borderRadius: 2 }}
                >
                    {availableYears.map(year => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ mt: 3, mb: 2 }}>
                {/* ResponsiveContainer to ensure the chart scales correctly */}
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={formattedData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={100}
                            fill="#8884d8"
                            label={({ name }) => name}
                        >
                            {formattedData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip formatter={(value) => currencyFormatter(value)} />
                    </PieChart>
                </ResponsiveContainer>
            </Box>

            {/* Mimic Tremor's List of items with category and amount */}
            <Typography variant="body2" color="white" sx={{ mb: 1 }}>Category / Amount</Typography>
            <List>
                {formattedData.map((item, index) => (
                    <ListItem key={item.name} sx={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 16, height: 16, backgroundColor: item.color, mr: 2 }} />
                            <Typography>{item.name}</Typography>
                        </Box>
                        <Typography>{currencyFormatter(item.value)}</Typography>
                        <Badge
                                badgeContent={`${item.percentage}%`}
                                color="primary"
                                sx={{
                                    backgroundColor: '#333',
                                    color: 'white',
                                    borderRadius: '4px',
                                    padding: '2px 8px',
                                    fontSize: '0.75rem',
                                }}
                            />
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default SalesChart;