import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const Header = ({ onLogout, idToken, userData, menuExpanded, setMenuExpanded }) => {
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuToggle = () => {
        setMenuExpanded(!menuExpanded); // Toggle menu expansion
    };

    const handleCopyIdToken = async () => {
        try {
            await navigator.clipboard.writeText(`Bearer ${idToken}`);
            alert('ID Token copied to clipboard');
        } catch (err) {
            console.error('Failed to copy ID token:', err);
        }
    };

    // Extract first letter of user name
    const avatarLetter = userData?.name?.[0]?.toUpperCase() || '';

    return (
        <>
            {/* Vertical AppBar with Toggleable Width */}
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: '#333',
                    width: menuExpanded ? '200px' : '80px',
                    height: '100vh',
                    transition: 'width 0.3s',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '16px',
                    left: 0, // Position on the left side
                }}
            >
                {/* Menu Toggle Button */}
                <IconButton
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenuToggle}
                    sx={{ marginBottom: '16px' }}
                >
                    <MenuIcon />
                </IconButton>

                {/* Placeholder Home Button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: menuExpanded ? 'flex-start' : 'center',
                        width: '100%',
                        paddingLeft: menuExpanded ? '16px' : 0,
                    }}
                >
                    <IconButton color="inherit" aria-label="home">
                        <HomeIcon />
                    </IconButton>
                    {menuExpanded && (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Home
                        </Typography>
                    )}
                </Box>

                {/* Placeholder Settings Button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: menuExpanded ? 'flex-start' : 'center',
                        width: '100%',
                        paddingLeft: menuExpanded ? '16px' : 0,
                        marginTop: '16px',
                    }}
                >
                    <IconButton color="inherit" aria-label="settings">
                        <SettingsIcon />
                    </IconButton>
                    {menuExpanded && (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Settings
                        </Typography>
                    )}
                </Box>

                {/* User Menu Avatar */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: menuExpanded ? 'flex-start' : 'center',
                        width: '100%',
                        paddingLeft: menuExpanded ? '16px' : 0,
                        marginTop: 'auto',
                        marginBottom: '16px',
                    }}
                >
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="User Avatar">{avatarLetter}</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>

                {/* Menu for Avatar - Opens upwards */}
                <Menu
                    sx={{ mt: '-45px' }}  // Ensure it opens upwards
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'bottom',   // Anchor menu to the bottom of the avatar
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',   // Menu will transform upwards
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem>{userData.name}</MenuItem>
                    {/* Copy ID Token Menu Item */}
                    <MenuItem
                        onClick={() => {
                            handleCopyIdToken();
                            handleCloseUserMenu();
                        }}
                    >
                        <Typography textAlign="center">Copy ID Token</Typography>
                    </MenuItem>

                    {/* Logout Menu Item */}
                    <MenuItem
                        onClick={() => {
                            onLogout();
                            handleCloseUserMenu();
                        }}
                    >
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </AppBar>
        </>
    );
};

export default Header;
