import React from 'react';
import { Box, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const MetricsCard = ({ title, value, change, isIncrease, icon }) => (
    <Box sx={{
        backgroundColor: "#1e1e1e",
        color: 'white',
        borderRadius: 2,
        boxShadow: 4,
        padding: 2,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    }}>
        <Box display="flex" alignItems="center" mb={1}>
            {icon}
            <Typography variant="h6" sx={{ marginLeft: 1 }}>{title}</Typography>
        </Box>
        <Typography variant="h3">{value}</Typography>
        <Box display="flex" alignItems="center" mt={2}>
            {isIncrease ? (
                <TrendingUpIcon sx={{ color: 'green', fontSize: 24, marginRight: 0.5 }} />
            ) : (
                <TrendingDownIcon sx={{ color: 'red', fontSize: 24, marginRight: 0.5 }} />
            )}
            <Typography variant="h6" fontWeight="bold" color={isIncrease ? "green" : "red"}>
                {change}%
            </Typography>
        </Box>
    </Box>
);

export default MetricsCard;
