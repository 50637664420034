import React, { useMemo, useState, useCallback, useEffect } from "react";
import Map, { Source, Layer, Popup, NavigationControl, FullscreenControl, ScaleControl, GeolocateControl } from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import supercluster from "supercluster";

const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'farms',
    filter: ['>', ['get', 'point_count'], 1],
    paint: {
        'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6', 10,
            '#f1f075', 50,
            '#f28cb1'
        ],
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            30,
            40,
            50
        ]
    }
};

const pointCountLayer = {
    id: 'point-count',
    type: 'symbol',
    source: 'farms',
    filter: ['>', ['get', 'point_count'], 1],
    layout: {
        'text-field': ['get', 'point_count'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
    },
    paint: {
        'text-color': '#000'
    }
};

const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'farms',
    filter: ['!', ['has', 'point_count']],
    paint: {
        'circle-color': '#11b4da',
        'circle-radius': 8,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
    }
};

const unclusteredPointCountLayer = {
    id: 'unclustered-point-count',
    type: 'symbol',
    source: 'farms',
    filter: ['!', ['has', 'point_count']],
    layout: {
        'text-field': '1',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
    },
    paint: {
        'text-color': '#000'
    }
};

const FullScreenMap = ({ farmLocations, selectedCountries, selectedStates, selectedCities, selectedFarmTypes, searchQuery, popupInfo, setPopupInfo, apiKey }) => {
    const [viewState, setViewState] = useState({
        longitude: 0,
        latitude: 0,
        zoom: 5
    });
    const [clusters, setClusters] = useState(null);

    // Calculate centroid of all farm locations
    const centroid = useMemo(() => {
        if (farmLocations.length === 0) return { lon: 0, lat: 0 };

        const totalCoords = farmLocations.reduce(
            (acc, farm) => {
                const lat = Number(farm.lat);
                const lon = Number(farm.lon);

                if (!isNaN(lat) && !isNaN(lon)) {
                    acc.lat += lat;
                    acc.lon += lon;
                    acc.count += 1;
                }
                return acc;
            },
            { lat: 0, lon: 0, count: 0 }
        );

        return {
            lat: totalCoords.lat / totalCoords.count,
            lon: totalCoords.lon / totalCoords.count
        };
    }, [farmLocations]);

    // Apply filters to farm locations based on dropdown selections and search query
    const filteredLocations = useMemo(() => {
        return farmLocations.filter((farm) => {
            const matchesCountry = selectedCountries.length === 0 || selectedCountries.includes(farm.country);
            const matchesState = selectedStates.length === 0 || selectedStates.includes(farm.state);
            const matchesCity = selectedCities.length === 0 || selectedCities.includes(farm.city);
            const matchesType = selectedFarmTypes.length === 0 || selectedFarmTypes.includes(farm.type);
            const searchQueryLower = searchQuery.toLowerCase();
            const matchesSearch =
                searchQueryLower === '' ||
                farm.name.toLowerCase().includes(searchQueryLower) ||
                farm.country.toLowerCase().includes(searchQueryLower) ||
                farm.state.toLowerCase().includes(searchQueryLower) ||
                farm.city.toLowerCase().includes(searchQueryLower) ||
                farm.type.toLowerCase().includes(searchQueryLower);

            return matchesCountry && matchesState && matchesCity && matchesType && matchesSearch;
        });
    }, [farmLocations, selectedCountries, selectedStates, selectedCities, selectedFarmTypes, searchQuery]);

    // Generate points and create supercluster instance
    const points = useMemo(() => filteredLocations.map((farm) => ({
        type: "Feature",
        properties: { farmId: farm.id, name: farm.name, country: farm.country, state: farm.state, city: farm.city, type: farm.type },
        geometry: {
            type: "Point",
            coordinates: [Number(farm.lon), Number(farm.lat)]
        }
    })), [filteredLocations]);

    const clusterIndex = useMemo(() => {
        const index = new supercluster({
            radius: 100,
            maxZoom: 16
        });
        index.load(points);
        return index;
    }, [points]);

    // Update clusters based on view
    const updateClusters = useCallback(() => {
        const bbox = [
            viewState.bounds?._sw?.lng || -180,
            viewState.bounds?._sw?.lat || -90,
            viewState.bounds?._ne?.lng || 180,
            viewState.bounds?._ne?.lat || 90
        ];
        const zoom = Math.floor(viewState.zoom);
        setClusters(clusterIndex.getClusters(bbox, zoom));
    }, [viewState, clusterIndex]);

    useEffect(() => {
        updateClusters();
    }, [viewState, updateClusters]);

    // Set viewState based on the centroid of the farm locations
    useEffect(() => {
        if (centroid.lat && centroid.lon) {
            setViewState({
                longitude: centroid.lon,
                latitude: centroid.lat,
                zoom: 5
            });
        }
    }, [centroid]);

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <Map
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                mapboxAccessToken={apiKey}
                style={{ width: '100vw', height: '100vh' }}
                onLoad={() => updateClusters()}
            >
                <Source
                    id="farms"
                    type="geojson"
                    data={{
                        type: "FeatureCollection",
                        features: clusters || []
                    }}
                    cluster={true}
                    clusterMaxZoom={14}
                    clusterRadius={50}
                >
                    <Layer {...clusterLayer} />
                    <Layer {...pointCountLayer} />
                    <Layer {...unclusteredPointLayer} />
                    <Layer {...unclusteredPointCountLayer} />
                </Source>

                {popupInfo && (
                    <Popup
                        longitude={Number(popupInfo.lon)}
                        latitude={Number(popupInfo.lat)}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div>{popupInfo.name}</div>
                    </Popup>
                )}

                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <ScaleControl />
            </Map>
        </div>
    );
};

export default FullScreenMap;
