import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell} from 'recharts';
import { Box, Typography } from '@mui/material';

const AvgAreaPerFarmTypeChart = ({ farmTypeData }) => {
    const transformedData = farmTypeData.map(item => ({
        ...item,
        avgArea: Math.round((item.avgArea / 10000) * 10) / 10  // Divide by 10,000
    }));

    return (
        <Box sx={{ padding: '16px', height: 350 }}> {/* Adjusted height for better spacing */}
            {/* Chart Title */}
            <Typography variant="h6" align="center" gutterBottom color='white'>
                Average Area per Farm Type (ha)
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={transformedData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, left: 0, bottom: 20 }} // Adjusted margins
                    barSize={16} // Thinner bars
                >
                    {/* X Axis */}
                    <XAxis type="number" tick={{ fontSize: 14 }}>
                    </XAxis>

                    {/* Y Axis with smaller font size and increased spacing */}
                    <YAxis
                        dataKey="type"
                        type="category"
                        width={120}
                        interval={0}
                        tick={{ fontSize: 14 }}
                    />

                    {/* Tooltip */}
                    <Tooltip />

                    {/* Bar with alternating colors */}
                    <Bar dataKey="avgArea" fill="#82ca9d">
                        {farmTypeData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={index % 2 === 0 ? "#82ca9d" : "#8884d8"} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default AvgAreaPerFarmTypeChart;
