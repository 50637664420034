import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, Typography } from '@mui/material';

// Custom color palette for each cost category
const colors = {
    fertilizerCost: '#82ca9d',
    fungicideCost: '#8884d8',
    insecticideCost: '#ff7300',
    herbicideCost: '#ffbb28',
};

const CostsBarChart = ({ data }) => {
    return (
        <Card sx={{ backgroundColor: '#1e1e1e', padding: 2, borderRadius: 4, boxShadow: 4 }}>
            <Typography variant="h6" color="white" sx={{ mb: 2 }}>Annual Costs by Category</Typography>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <XAxis dataKey="year" stroke="#ffffff" />
                    <YAxis stroke="#ffffff" />
                    <Tooltip />
                    <Legend />
                    {/* Individual bars for each cost category */}
                    <Bar dataKey="fertilizerCost" fill={colors.fertilizerCost} name="Fertilizer Cost" />
                    <Bar dataKey="fungicideCost" fill={colors.fungicideCost} name="Fungicide Cost" />
                    <Bar dataKey="insecticideCost" fill={colors.insecticideCost} name="Insecticide Cost" />
                    <Bar dataKey="herbicideCost" fill={colors.herbicideCost} name="Herbicide Cost" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default CostsBarChart;
