import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import { Box } from '@mui/material';

const FarmTypeCountChart = ({ farmTypeData }) => {
    const COLORS = ['#82ca9d', '#8884d8', '#ff7300', '#ffbb28', '#d0ed57'];

    return (
        <Box sx={{height: 300 }}>  {/* Adjusted height for Pie chart */}
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                        iconType="circle"
                        formatter={(value) => <span style={{ fontSize: '12px', color: 'white' }}>{value}</span>}  // Smaller text size
                    />
                    <Pie
                        data={farmTypeData}
                        dataKey="count"
                        nameKey="type"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}  // Makes it a donut chart
                        outerRadius={100}  // Adjust the outer radius for size
                        fill="#8884d8"
                        paddingAngle={0}  // Adds space between the segments
                    >
                        {farmTypeData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default FarmTypeCountChart;
