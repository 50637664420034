import React, { useEffect, useState } from 'react';
import { Box, TextField, Autocomplete, Chip } from '@mui/material';

const FarmsDropdowns = ({
  farmLocations,
  selectedCountries, setSelectedCountries,
  selectedStates, setSelectedStates,
  selectedCities, setSelectedCities,
  selectedFarmTypes, setSelectedFarmTypes
}) => {

  // State variables to store filtered states and cities
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  // Extract unique farm types for farm type dropdown
  const uniqueFarmTypes = [...new Set(farmLocations.map(farm => farm.type))];

  // Filter states based on selected countries
  useEffect(() => {
    if (selectedCountries.length > 0) {
      const states = [
        ...new Set(
          farmLocations
            .filter(farm => selectedCountries.includes(farm.country))
            .map(farm => farm.state)
        )
      ];
      setFilteredStates(states);
      setSelectedStates([]);  // Reset states when countries change
      setSelectedCities([]);  // Reset cities when countries change
    } else {
      setFilteredStates([]);  // Clear states if no country is selected
      setSelectedStates([]);  // Reset states
      setSelectedCities([]);  // Reset cities
    }
  }, [selectedCountries, farmLocations, setSelectedStates, setSelectedCities]);

  // Filter cities based on selected states
  useEffect(() => {
    if (selectedStates.length > 0) {
      const cities = [
        ...new Set(
          farmLocations
            .filter(farm => selectedCountries.includes(farm.country) && selectedStates.includes(farm.state))
            .map(farm => farm.city)
        )
      ];
      setFilteredCities(cities);
      setSelectedCities([]);  // Reset cities when states change
    } else {
      setFilteredCities([]);  // Clear cities if no state is selected
      setSelectedCities([]);  // Reset cities
    }
  }, [selectedStates, selectedCountries, farmLocations, setSelectedCities]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 2,
          flexDirection: 'row',
        }}
      >
        {/* Country Dropdown */}
        <Autocomplete
          multiple
          options={[...new Set(farmLocations.map(farm => farm.country))]}
          value={selectedCountries}
          onChange={(event, newValue) => setSelectedCountries(newValue)}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                sx={{ backgroundColor: '#b19cd9', color: '#fff' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="outlined"
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                '& .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .Mui-focused .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#b19cd9', // Light purple outline when selectable
                  },
                  '&:hover fieldset': {
                    borderColor: '#b19cd9', // Light purple on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#b19cd9', // Light purple when focused
                  },
                },
              }}
            />
          )}
          filterSelectedOptions
          disableCloseOnSelect
          sx={{ width: '22%' }}
        />

        {/* State Dropdown */}
        <Autocomplete
          multiple
          options={filteredStates}
          value={selectedStates}
          onChange={(event, newValue) => setSelectedStates(newValue)}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                sx={{ backgroundColor: '#b19cd9', color: '#fff' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                '& .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .Mui-focused .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: selectedCountries.length ? '#b19cd9' : 'red', // Light purple if selectable, red if not
                  },
                  '&:hover fieldset': {
                    borderColor: selectedCountries.length ? '#b19cd9' : 'red', // Light purple if selectable, red if not
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: selectedCountries.length ? '#b19cd9' : 'red', // Light purple if selectable, red if not
                  },
                },
              }}
            />
          )}
          filterSelectedOptions
          disableCloseOnSelect
          sx={{ width: '22%' }}
          disabled={!selectedCountries.length}
        />

        {/* City Dropdown */}
        <Autocomplete
          multiple
          options={filteredCities}
          value={selectedCities}
          onChange={(event, newValue) => setSelectedCities(newValue)}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                sx={{ backgroundColor: '#b19cd9', color: '#fff' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              variant="outlined"
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                '& .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .Mui-focused .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: selectedStates.length ? '#b19cd9' : 'red', // Light purple if selectable, red if not
                  },
                  '&:hover fieldset': {
                    borderColor: selectedStates.length ? '#b19cd9' : 'red', // Light purple if selectable, red if not
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: selectedStates.length ? '#b19cd9' : 'red', // Light purple if selectable, red if not
                  },
                },
              }}
            />
          )}
          filterSelectedOptions
          disableCloseOnSelect
          sx={{ width: '22%' }}
          disabled={!selectedStates.length}
        />

        {/* Farm Type Dropdown */}
        <Autocomplete
          multiple
          options={uniqueFarmTypes}
          value={selectedFarmTypes}
          onChange={(event, newValue) => setSelectedFarmTypes(newValue)}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                sx={{ backgroundColor: '#b19cd9', color: '#fff' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Farm Type"
              variant="outlined"
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                '& .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .Mui-focused .MuiInputLabel-root': {
                  color: '#fff',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#b19cd9', // Light purple outline
                  },
                  '&:hover fieldset': {
                    borderColor: '#b19cd9', // Light purple on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#b19cd9', // Light purple when focused
                  },
                },
              }}
            />
          )}
          filterSelectedOptions
          disableCloseOnSelect
          sx={{ width: '22%' }}
        />
      </Box>
    </Box>
  );
};

export default FarmsDropdowns;
