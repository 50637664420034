import React, { useState, useMemo } from "react";
import {
  Box, Drawer, Typography, Card, CardContent, Divider, IconButton, List, Tabs, Tab
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import FarmTypeCountChart from './FarmTypeCountChart';
import AvgAreaPerFarmTypeChart from './AvgAreaPerFarmTypeChart';
import { useNavigate } from "react-router-dom"; // Import useNavigate

const FarmsInfoPanel = ({
  farmLocations,
  selectedCountries,
  selectedStates,
  selectedCities,
  selectedFarmTypes,
  searchQuery,
  isDrawerOpen,
  setIsDrawerOpen,
  idToken
}) => {
  const [filteredFarms, setFilteredFarms] = useState(farmLocations);
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate(); // Initialize navigate

  // Toggle drawer
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Handle card click
  const handleCardClick = (farmId, farmZones) => {
    navigate(`/farm/${farmId}`, { state: { idToken, zones: farmZones } }); // Navigate to the new page with farmId and zones
  };

  // Filter farms based on user selections
  useMemo(() => {
    const filtered = farmLocations.filter((farm) => {
      const matchesCountry = selectedCountries.length === 0 || selectedCountries.includes(farm.country);
      const matchesState = selectedStates.length === 0 || selectedStates.includes(farm.state);
      const matchesCity = selectedCities.length === 0 || selectedCities.includes(farm.city);

      const matchesType = selectedFarmTypes.length === 0 || selectedFarmTypes.includes(farm.type);
      const searchQueryLower = searchQuery.toLowerCase();
      const matchesSearch =
        searchQueryLower === '' ||
        farm.name.toLowerCase().includes(searchQueryLower) ||
        farm.country.toLowerCase().includes(searchQueryLower) ||
        farm.state.toLowerCase().includes(searchQueryLower) ||
        farm.city.toLowerCase().includes(searchQueryLower) ||
        farm.type.toLowerCase().includes(searchQueryLower);

      return matchesCountry && matchesState && matchesCity && matchesType && matchesSearch;
    });

    setFilteredFarms(filtered);
  }, [farmLocations, selectedCountries, selectedStates, selectedCities, selectedFarmTypes, searchQuery]);

  // Count farm types and calculate average area per farm type
  const farmTypeData = useMemo(() => {
    const typeData = {};

    filteredFarms.forEach(farm => {
      if (farm.type) {
        if (!typeData[farm.type]) {
          typeData[farm.type] = {
            count: 0,
            totalArea: 0,
          };
        }
        typeData[farm.type].count += 1;
        typeData[farm.type].totalArea += farm.area || 0;
      }
    });

    return Object.keys(typeData).map(type => ({
      type,
      count: typeData[type].count,
      avgArea: typeData[type].totalArea / typeData[type].count
    }));
  }, [filteredFarms]);

  return (
    <Drawer
      anchor="right"
      variant="permanent"
      open={isDrawerOpen}
      onClose={handleDrawerToggle}
      sx={{
        width: isDrawerOpen ? 400 : 60,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isDrawerOpen ? 400 : 60,
          backgroundColor: '#333',
          transition: 'width 0.3s',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px', justifyContent: isDrawerOpen ? 'space-between' : 'center' }}>
        {isDrawerOpen ? (
          <>
            <Typography variant="h6" color="white">Farms Overview</Typography>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronRightIcon sx={{ color: 'white' }} />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={handleDrawerToggle}>
            <InfoIcon sx={{ color: 'white' }} />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ backgroundColor: 'white' }} />

      {isDrawerOpen && (
        <Box sx={{ padding: '16px' }}>
          <Card sx={{ backgroundColor: '#444', color: 'white' }}>
            <CardContent>
              <Typography variant="h4" align="center">
                {filteredFarms.length}
              </Typography>
              <Typography variant="subtitle1" align="center">
                Total Farms
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}

      {isDrawerOpen && (
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="inherit"
          indicatorColor="primary"
          centered
          sx={{
            '& .MuiTabs-flexContainer': {
              borderBottom: '1px solid #eee',
            },
            '& .MuiTab-root': {
              color: 'white',
              minWidth: '50%',
              flex: 1,
            },
            '& .Mui-selected': {
              color: 'white',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#D8BFD8',
            }
          }}
        >
          <Tab label="Farms List" />
          <Tab label="Statistics" />
        </Tabs>
      )}

      <Divider sx={{ backgroundColor: 'white' }} />

      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '16px' }}>
        {selectedTab === 0 && isDrawerOpen && (
          <List sx={{ width: '100%', padding: '16px', backgroundColor: '#333' }}>
            {filteredFarms.map((farm) => (
              <Card
                key={farm.id}
                sx={{ marginBottom: '16px', backgroundColor: '#444', color: 'white', cursor: 'pointer' }}
                onClick={() => handleCardClick(farm.id, farm.zones)} // Card is clickable
              >
                <CardContent>
                  <Typography variant="h6">{farm.name}</Typography>
                  <Typography variant="body2">Country: {farm.country}</Typography>
                  <Typography variant="body2">State: {farm.state}</Typography>
                  <Typography variant="body2">City: {farm.city}</Typography>
                  <Typography variant="body2">Type: {farm.type}</Typography>
                  <Typography variant="body2">Area: {farm.area} {farm.area_unit}</Typography>
                  <Typography variant="body2">Zones: {farm.zones.length}</Typography>
                </CardContent>
              </Card>
            ))}
          </List>
        )}

        {selectedTab === 1 && isDrawerOpen && (
          <Box sx={{ padding: '16px' }}>
            <FarmTypeCountChart farmTypeData={farmTypeData} />
            <Divider sx={{ backgroundColor: 'white', margin: '16px 0' }} />
            <AvgAreaPerFarmTypeChart farmTypeData={farmTypeData} />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default FarmsInfoPanel;
